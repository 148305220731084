import "../App.css";

function WhatWe() {
  return (
    <>
      {/* large screen */}
      <div className=" mt-[7rem] hidden  lg:block w-full max-w-full  mr-[90px]">
        <div className=" items-center flex justify-center  text-center  ">
          <div className="w-[50%]">
            <h1 className=" text-[#00325c] text-[42px] font-normal  ">What We Do</h1>
            <p className=" text-[#0098db]  mt-[12px] text-[16px] font-normal  ">
              We are a company pushing the boundaries of innovation in Digital Insurance, Digital Banking, Ecommerce, Talent Sourcing and business process optimization. Our promise is that we build products that serve as growth enablers, serving the needs of all our customers.
          </p>
          </div>
        </div>
        <div className=" flex flex-col justify-center mt-[4rem] items-center w-full ">
          <div className=" w-[70%] ">
            <div className="flex  items-center  justify-between  ">
              {/* box 1 */}
              <div className="flex  justify-between">
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18 7V17C18 17.62 17.98 18.17 17.91 18.66C17.62 21.29 16.38 22 13 22H11C7.62 22 6.38 21.29 6.09 18.66C6.02 18.17 6 17.62 6 17V7C6 6.38 6.02 5.83 6.09 5.34C6.38 2.71 7.62 2 11 2H13C16.38 2 17.62 2.71 17.91 5.34C17.98 5.83 18 6.38 18 7Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      opacity="0.4"
                      d="M6 17.0001C6 17.6201 6.02 18.1701 6.09 18.6601C5.95 18.6701 5.82 18.6701 5.67 18.6701H5.33C2.67 18.6701 2 18.0001 2 15.3301V8.67008C2 6.00008 2.67 5.33008 5.33 5.33008H5.67C5.82 5.33008 5.95 5.33008 6.09 5.34008C6.02 5.83008 6 6.38008 6 7.00008V17.0001Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      opacity="0.4"
                      d="M22.0002 8.67008V15.3301C22.0002 18.0001 21.3302 18.6701 18.6702 18.6701H18.3302C18.1802 18.6701 18.0502 18.6701 17.9102 18.6601C17.9802 18.1701 18.0002 17.6201 18.0002 17.0001V7.00008C18.0002 6.38008 17.9802 5.83008 17.9102 5.34008C18.0502 5.33008 18.1802 5.33008 18.3302 5.33008H18.6702C21.3302 5.33008 22.0002 6.00008 22.0002 8.67008Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="  ml-[20px] text-left ">
                  <h1 className=" font-normal mb-1 text-[18px] text-[#00325c] ">InsurTech Platform</h1>

                 
                  <p className=" w-3/4 font-normal  text-[14px] text-[#0098db] ">
                  Our innovative Insurtech product is a powerful AI-driven platform that revolutionizes the insurance experience. Our product intelligently handles tasks typically performed by brokers{" "}             
                  </p>
                </div>
              </div>
              {/* box 2 */}
              <a className="  " href="https://relief.nubeero.com/">
                <div className="flex justify-between">
                  <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.4"
                        d="M12 17V19.38C12 21.25 11.25 22 9.37 22H4.62C2.75 22 2 21.25 2 19.38V14.63C2 12.75 2.75 12 4.62 12H7V14.37C7 16.25 7.75 17 9.62 17H12Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 12V14.37C17 16.25 16.25 17 14.37 17H9.62C7.75 17 7 16.25 7 14.37V9.62C7 7.75 7.75 7 9.62 7H12V9.37C12 11.25 12.75 12 14.62 12H17Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 4.62V9.37C22 11.25 21.25 12 19.37 12H14.62C12.75 12 12 11.25 12 9.37V4.62C12 2.75 12.75 2 14.62 2H19.37C21.25 2 22 2.75 22 4.62Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className=" ml-[20px] text-left ">
                    <h1 className=" font-normal mb-1 text-[18px] text-[#00325c] ">Merchant Services</h1>

                    <p className=" font-normal  text-[14px] text-[#0098db] ">
                    Maximize your business's operations with innovative and trusted merchant services, 
                    payment processing services and solutions.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="flex  items-center  mt-[3rem] justify-between  ">
              {/* box 3 */}
              <div className="flex w-[70%]  justify-between">
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8H8V16H16V8Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 22C6.65 22 8 20.65 8 19V16H5C3.35 16 2 17.35 2 19C2 20.65 3.35 22 5 22Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M5 8H8V5C8 3.35 6.65 2 5 2C3.35 2 2 3.35 2 5C2 6.65 3.35 8 5 8Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16 8H19C20.65 8 22 6.65 22 5C22 3.35 20.65 2 19 2C17.35 2 16 3.35 16 5V8Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M19 22C20.65 22 22 20.65 22 19C22 17.35 20.65 16 19 16H16V19C16 20.65 17.35 22 19 22Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="  ml-[20px] text-left ">
                  <h1 className=" font-normal mb-1 text-[18px] text-[#00325c] ">Software Development</h1>

                  <p className=" w-3/4 font-normal  text-[14px] text-[#0098db] ">
                    We are positioned to help businesses take advantage of emerging technologies as well as the ability to absorb the technology 
                    while also performing corresponding research and development and training activities{" "}
                  </p>
                </div>
              </div>
              {/* box 4 */}

              <div className="flex w-[50%]  justify-between">
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.4"
                      d="M20 6.95V17.05C19.84 17.02 19.67 17 19.5 17C18.12 17 17 18.12 17 19.5C17 19.67 17.02 19.84 17.05 20H6.95C6.98 19.84 7 19.67 7 19.5C7 18.12 5.88 17 4.5 17C4.33 17 4.16 17.02 4 17.05V6.95C4.16 6.98 4.33 7 4.5 7C5.88 7 7 5.88 7 4.5C7 4.33 6.98 4.16 6.95 4H17.05C17.02 4.16 17 4.33 17 4.5C17 5.88 18.12 7 19.5 7C19.67 7 19.84 6.98 20 6.95Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 4.5C7 5.88 5.88 7 4.5 7C4.33 7 4.16 6.98 4 6.95C2.86 6.72 2 5.71 2 4.5C2 3.12 3.12 2 4.5 2C5.71 2 6.72 2.86 6.95 4C6.98 4.16 7 4.33 7 4.5Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 4.5C22 5.71 21.14 6.72 20 6.95C19.84 6.98 19.67 7 19.5 7C18.12 7 17 5.88 17 4.5C17 4.33 17.02 4.16 17.05 4C17.28 2.86 18.29 2 19.5 2C20.88 2 22 3.12 22 4.5Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 19.5C7 19.67 6.98 19.84 6.95 20C6.72 21.14 5.71 22 4.5 22C3.12 22 2 20.88 2 19.5C2 18.29 2.86 17.28 4 17.05C4.16 17.02 4.33 17 4.5 17C5.88 17 7 18.12 7 19.5Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 19.5C22 20.88 20.88 22 19.5 22C18.29 22 17.28 21.14 17.05 20C17.02 19.84 17 19.67 17 19.5C17 18.12 18.12 17 19.5 17C19.67 17 19.84 17.02 20 17.05C21.14 17.28 22 18.29 22 19.5Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className=" ml-[20px] text-left ">
                  <h1 className=" font-normal mb-1 text-[18px] text-[#00325c] ">Intermediation</h1>

                  <p className=" font-normal    text-[14px] text-[#0098db] ">
                    We are an experienced Enterprise Solutions delivery company with proven track records in various sectors, including Digital Insurance Solutions, Commercial banking, Digital Banking, Agency Banking, Digital Insurance...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREEN */}
      <div className=" mt-[4rem] hidden phone:block  lg:hidden w-full px-10  ">
        <div className=" items-center flex justify-center  text-center  ">
          <div className="w-[70%]">
            <div className="">
              <h1 className=" text-[#00325c] text-[22px] font-normal  ">What We Do</h1>
              <p className=" text-[#0098db]  mt-[12px] text-[14px] font-normal  ">
                We provide innovative solutions for insurance, business process optimization & ecommerce service. Our promise is that we are growth enablers,serving the needs of the industries in which we play.
              </p>
            </div>
            <div className=" flex flex-col mt-[60px] justify-between items-center  ">
              {/* box 1 */}
              <div className="flex  justify-between">
                <div>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18 7V17C18 17.62 17.98 18.17 17.91 18.66C17.62 21.29 16.38 22 13 22H11C7.62 22 6.38 21.29 6.09 18.66C6.02 18.17 6 17.62 6 17V7C6 6.38 6.02 5.83 6.09 5.34C6.38 2.71 7.62 2 11 2H13C16.38 2 17.62 2.71 17.91 5.34C17.98 5.83 18 6.38 18 7Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      opacity="0.4"
                      d="M6 17.0001C6 17.6201 6.02 18.1701 6.09 18.6601C5.95 18.6701 5.82 18.6701 5.67 18.6701H5.33C2.67 18.6701 2 18.0001 2 15.3301V8.67008C2 6.00008 2.67 5.33008 5.33 5.33008H5.67C5.82 5.33008 5.95 5.33008 6.09 5.34008C6.02 5.83008 6 6.38008 6 7.00008V17.0001Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      opacity="0.4"
                      d="M22.0002 8.67008V15.3301C22.0002 18.0001 21.3302 18.6701 18.6702 18.6701H18.3302C18.1802 18.6701 18.0502 18.6701 17.9102 18.6601C17.9802 18.1701 18.0002 17.6201 18.0002 17.0001V7.00008C18.0002 6.38008 17.9802 5.83008 17.9102 5.34008C18.0502 5.33008 18.1802 5.33008 18.3302 5.33008H18.6702C21.3302 5.33008 22.0002 6.00008 22.0002 8.67008Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="  ml-[20px] text-left ">
                  <h1 className=" font-normal mb-1 text-[16px] text-[#00325c] ">Insurance Tech</h1>

                  <p className="  font-normal  text-[13px] text-[#0098db] ">
                    We are using deep learning trained artificial inteligence (AI) to handle the tasks of insurance brokers and find the right mix of policies to complete an individuals or Group coverage.
                  </p>
                </div>
              </div>
              {/* box 2 */}
              <a className=" mt-[60px]  " href="https://relief.nubeero.com/">
                <div className="flex justify-between">
                  <div>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        opacity="0.4"
                        d="M12 17V19.38C12 21.25 11.25 22 9.37 22H4.62C2.75 22 2 21.25 2 19.38V14.63C2 12.75 2.75 12 4.62 12H7V14.37C7 16.25 7.75 17 9.62 17H12Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 12V14.37C17 16.25 16.25 17 14.37 17H9.62C7.75 17 7 16.25 7 14.37V9.62C7 7.75 7.75 7 9.62 7H12V9.37C12 11.25 12.75 12 14.62 12H17Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 4.62V9.37C22 11.25 21.25 12 19.37 12H14.62C12.75 12 12 11.25 12 9.37V4.62C12 2.75 12.75 2 14.62 2H19.37C21.25 2 22 2.75 22 4.62Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className=" ml-[20px] text-left ">
                    <h1 className=" font-normal mb-1 text-[16px] text-[#00325c] ">Merchant Services</h1>

                    <p className=" font-normal  text-[13px] text-[#0098db] ">Maximize your business's operations with innovative and trusted merchant services, payment processing services and solutions.</p>
                  </div>
                </div>
              </a>
              {/* box 3 */}
              <div className="flex mt-[60px]  justify-between">
                <div>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8H8V16H16V8Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 22C6.65 22 8 20.65 8 19V16H5C3.35 16 2 17.35 2 19C2 20.65 3.35 22 5 22Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M5 8H8V5C8 3.35 6.65 2 5 2C3.35 2 2 3.35 2 5C2 6.65 3.35 8 5 8Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16 8H19C20.65 8 22 6.65 22 5C22 3.35 20.65 2 19 2C17.35 2 16 3.35 16 5V8Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M19 22C20.65 22 22 20.65 22 19C22 17.35 20.65 16 19 16H16V19C16 20.65 17.35 22 19 22Z" stroke="#00325c" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="  ml-[20px] text-left ">
                  <h1 className=" font-normal mb-1 text-[16px] text-[#00325c] ">Software Development</h1>

                  <p className="  font-normal  text-[13px] text-[#0098db] ">
                    We are positioned to help businesses take advantage of emerging technologies as well as the ability to absorb the technology while also performing corresponding research and development and training activities{" "}
                  </p>
                </div>
              </div>
              {/* box 4 */}

              <div className="flex mt-[60px]  justify-between">
                <div>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.4"
                      d="M20 6.95V17.05C19.84 17.02 19.67 17 19.5 17C18.12 17 17 18.12 17 19.5C17 19.67 17.02 19.84 17.05 20H6.95C6.98 19.84 7 19.67 7 19.5C7 18.12 5.88 17 4.5 17C4.33 17 4.16 17.02 4 17.05V6.95C4.16 6.98 4.33 7 4.5 7C5.88 7 7 5.88 7 4.5C7 4.33 6.98 4.16 6.95 4H17.05C17.02 4.16 17 4.33 17 4.5C17 5.88 18.12 7 19.5 7C19.67 7 19.84 6.98 20 6.95Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 4.5C7 5.88 5.88 7 4.5 7C4.33 7 4.16 6.98 4 6.95C2.86 6.72 2 5.71 2 4.5C2 3.12 3.12 2 4.5 2C5.71 2 6.72 2.86 6.95 4C6.98 4.16 7 4.33 7 4.5Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 4.5C22 5.71 21.14 6.72 20 6.95C19.84 6.98 19.67 7 19.5 7C18.12 7 17 5.88 17 4.5C17 4.33 17.02 4.16 17.05 4C17.28 2.86 18.29 2 19.5 2C20.88 2 22 3.12 22 4.5Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 19.5C7 19.67 6.98 19.84 6.95 20C6.72 21.14 5.71 22 4.5 22C3.12 22 2 20.88 2 19.5C2 18.29 2.86 17.28 4 17.05C4.16 17.02 4.33 17 4.5 17C5.88 17 7 18.12 7 19.5Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 19.5C22 20.88 20.88 22 19.5 22C18.29 22 17.28 21.14 17.05 20C17.02 19.84 17 19.67 17 19.5C17 18.12 18.12 17 19.5 17C19.67 17 19.84 17.02 20 17.05C21.14 17.28 22 18.29 22 19.5Z"
                      stroke="#00325c"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className=" ml-[20px] text-left ">
                  <h1 className=" font-normal mb-1 text-[16px] text-[#00325c] ">Intermediation</h1>

                  <p className=" font-normal    text-[13px] text-[#0098db] ">
                    We are an experienced Enterprise Solutions delivery company with proven track records in various sectors, including Digital Insurance Solutions, Commercial banking, Digital Banking, Agency Banking, Digital Insurance...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatWe;
